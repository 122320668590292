//////////////////////       Swagger AUTH          //////////////////////

import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosRequest } from "../../../utils/axiosURL";
import { handleBinaryData, resetLeftMenu } from "../../../utils/globalFunction";
import { message } from "antd";
import { documentSignFetch } from "./StorageQueries";

////////      accounting      ////////

////////   Business Trip    ////////
// businessTrip download certificate
export const businessTripDownloadCertificate = createAsyncThunk(
  "businessTripDownloadCertificate",
  async ({ id, user_id }) => {
    try {
      const { data } = await axiosRequest.get(
        `accounting/v1/businessTrip/download/certificate?_id=${id}&user_id=${user_id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// businessTrip approve
export const businessTripApprove = createAsyncThunk(
  "businessTripApprove",
  async ({ id, user_id }) => {
    try {
      const { data } = await axiosRequest.get(
        `accounting/v1/businessTrip/approve?_id=${id}&user_id=${user_id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

////////      Cash      ////////
// get cash salary earley receipt
export const getCashSER = createAsyncThunk("getCashSER", async (body) => {
  try {
    const { data } = await axiosRequest.post(
      `accounting/v1/cash/get/salary_early_receipt`,
      body
    );
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

// put cash salary earley receipt
export const putCashSER = createAsyncThunk(
  "putCashSER",
  async ({ body, query, user, getBody, isRejected = false }, { dispatch }) => {
    try {
      const { data } = await axiosRequest.put(
        `accounting/v1/cash/put/salary_early_receipt${query}`,
        body
      );

      if (isRejected) {
        message.success("Отменён");
        resetLeftMenu(dispatch, [2, 3, 4]);
        return data;
      }

      if (data.status === 200) {
        if (user.role?.role_id === 9) {
          body.append("specialist", true);
        } else if (user.role?.role_id === 7) {
          body.append("accountant", true);
        } else if (user.role?.role_id === 2) {
          body.append("principal", true);
        } else if (user.role?.role_id === 11) {
          body.append("cashier", true);
        }

        const { data: cashData } = await axiosRequest.post(
          `accounting/v1/cash/get/salary_early_receipt`,
          getBody
        );

        if (user.role?.role_id !== 11) {
          await cashData[0]?.uuids?.map(async (file_id) => {
            await documentSignFetch({
              user_id: user.user_id,
              file_id,
            });
          });
        }

        await axiosRequest.put(
          `accounting/v1/cash/put/salary_early_receipt${query}`,
          body
        );

        setTimeout(() => {
          dispatch(getCashSER(getBody));
        }, 1000);

        message.success("Документ подписан", 5);
        return { data, cashData };
      }
      resetLeftMenu(dispatch, [2, 3, 4]);
      return { data };
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get approved salary early recipt
export const getApprovedSER = createAsyncThunk(
  "getApprovedSER",
  async ({ body, query }, { dispatch }) => {
    try {
      const { data } = await axiosRequest.get(
        `accounting/v1/cash/approved/salary_early_receipt${query}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get rejected salary early recipt
export const getRejectedSER = createAsyncThunk(
  "getRejectedSER",
  async ({ body, query }, { dispatch }) => {
    try {
      const { data } = await axiosRequest.get(
        `accounting/v1/cash/rejected/salary_early_receipt${query}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// post cash document 4
export const postCashDocument4 = createAsyncThunk(
  "postCashDocument4",
  async ({ body, query, approved }, { dispatch }) => {
    try {
      const { data } = await axiosRequest.post(
        `accounting/v1/cash/document4${query}`,
        body,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (approved) {
        // dispatch()
        // dispatch(
        //   setDocsModal({
        //     open: true,
        //     details: {
        //       doc_id: el?.doc_id,
        //     },
        //   })
        // )
      }
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get cash document 4 approved ones
export const getCashDocument4ApprovedOnes = createAsyncThunk(
  "getCashDocument4ApprovedOnes",
  async ({ staff_id, department_id, year }) => {
    try {
      const { data } = await axiosRequest.get(
        `accounting/v1/cash/document4/approvedOnes?staff_id=${staff_id}&department_id=${department_id}${
          year ? `&_year=${year}` : ""
        }`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get cash book
export const getCashBook = createAsyncThunk("getCashBook", async ({ year }) => {
  try {
    const { data } = await axiosRequest.get(
      `accounting/v1/cash/cach_book?_year=${year}`
    );
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

// get cash memorial warrant1
export const getCashMemorialWarrant1 = createAsyncThunk(
  "getCashMemorialWarrant1",
  async ({ year }) => {
    try {
      const { data } = await axiosRequest.get(
        `accounting/v1/cash/memorial_warrant1?_year=${year}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// cash download document 4
export const cashDownloadDocument4 = createAsyncThunk(
  "cashDownloadDocument4",
  async ({ id, user_id }) => {
    try {
      const { data } = await axiosRequest.get(
        `accounting/v1/cash/download/document4?_id=${id}&user_id=${user_id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// cash download rko
export const cashDownloadRKO = createAsyncThunk(
  "cashDownloadRKO",
  async ({ id, user_id }) => {
    try {
      const { data } = await axiosRequest.get(
        `accounting/v1/cash/download/rko?_id=${id}&user_id=${user_id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get cash account plan
export const getCashAccountPlan = createAsyncThunk(
  "getCashAccountPlan",
  async ({ id, user_id }) => {
    try {
      const { data } = await axiosRequest.get(
        `accounting/v1/cash/account_plan`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// post cash account plan
export const postCashAccountPlan = createAsyncThunk(
  "postCashAccountPlan",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        `accounting/v1/cash/account_plan`,
        body
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get cash memorial warrant8
export const getCashMemorialWarrant8 = createAsyncThunk(
  "getCashMemorialWarrant8",
  async ({ year }) => {
    try {
      const { data } = await axiosRequest.get(
        `accounting/v1/cash/memorial_warrant8?_year=${year}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// post cash arrival_order
export const postCashArrivalOrder = createAsyncThunk(
  "postCashArrivalOrder",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        `accounting/v1/cash/arrival_order`,
        body,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get cash arrival_order
export const getCashArrivalOrder = createAsyncThunk(
  "getCashArrivalOrder",
  async ({ year }) => {
    try {
      const { data } = await axiosRequest.get(
        `accounting/v1/cash/arrival_order?_year=${year}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);
// accounting/v1/counterparty

export const postUpdateAgreements = createAsyncThunk(
  "postUpdateAgreements",
  async ({ head, body }, { dispatch }) => {
    try {
      const params = new URLSearchParams();
      params.append("user_id", head.user_id);
      params.append("name", head.name);
      params.append("itn", head.itn);
      params.append("bank_details", head.bank_details);
      params.append("doc_types", head.doc_types);
      params.append("metadatas", head.metadatas);
      const { data } = await axiosRequest.post(
        `accounting/v1/counterparty?${params.toString()}`,
        body,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      dispatch(getUpdateAgreements());
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const getUpdateAgreements = createAsyncThunk(
  "getUpdateAgreements",
  async (itn = null, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.get(
        `accounting/v1/counterparty${itn ? `/itn?itn=${itn}` : ""}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response?.data || "Ошибка загрузки данных");
    }
  }
);
// getWarehouseDeffectsByStaffIdOrNameId
export const getWarehouseDeffectsByStaffIdOrNameId = createAsyncThunk(
  "getWarehouseDeffectsByStaffIdOrNameId",
  async ({ staff_id = null, name_id = null }, { rejectWithValue }) => {
    try {
      const params = new URLSearchParams();
      if (staff_id) params.append("staff_id", staff_id);
      if (name_id) params.append("name_id", name_id);
      const { data } = await axiosRequest.get(
        `accounting/v1/warehouse/defects?${params}`
      );

      return data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response?.data || "Ошибка загрузки данных");
    }
  }
);
// postWarehouseDefects
export const postWarehouseDefects = createAsyncThunk(
  "postWarehouseDefects",
  async (body = null, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosRequest.post(
        `/accounting/v1/warehouse/defects`,
        body
      );
      dispatch(getWarehouseDeffectsByStaffIdOrNameId(body.staff_id));
      return data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response?.data || "Ошибка загрузки данных");
    }
  }
);

// getAccountingOutgoingData
export const getAccountingOutgoingData = createAsyncThunk(
  "getAccountingOutgoingData",
  async ({ staff_id = null, name_id = null }, { rejectWithValue }) => {
    try {
      const queryParams = new URLSearchParams({
        ...(staff_id && { staff_id }),
        ...(name_id && { name_id }),
      }).toString();
      const { data } = await axiosRequest.get(
        `/accounting/v1/warehouse/outgoing${
          queryParams ? `?${queryParams}` : ""
        }`
      );
      return data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response?.data || "Ошибка загрузки данных");
    }
  }
);
// getAccountingOutgoingData
export const getAccountingOutgoingSearch = createAsyncThunk(
  "getAccountingOutgoingSearch",
  async ({ staff_id = null, name_id = null }, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.get(
        `/accounting/v1/warehouse/outgoing?staff_id=${staff_id}&name_id=${name_id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response?.data || "Ошибка загрузки данных");
    }
  }
);
// postAccountingOutgoingData
export const postAccountingOutgoingData = createAsyncThunk(
  "postAccountingOutgoingData",
  async (
    {
      name_id = null,
      staff_id = null,
      user_id = null,
      quantity = null,
      formData,
    },
    { rejectWithValue }
  ) => {
    try {
      await axiosRequest.post(
        `/accounting/v1/warehouse/outgoing?name_id=${name_id}&staff_id=${staff_id}&user_id=${user_id}&quantity=${quantity}`,
        formData
      );
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response?.data || "Ошибка загрузки данных");
    }
  }
);

export const getBusinessTripCahs = createAsyncThunk(
  "getBusinessTripCahs",
  async (body = null, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.get(
        `accounting/v1/businessTrip/staff_all_records?staff_id=${body.staff_id}&dep=${body.dep}&year=${body.year}`
      );

      return data;
    } catch (error) {
      console.error("Error fetching business trip data:", error);
      return rejectWithValue(error.response?.data || "Ошибка загрузки данных");
    }
  }
);

// sertificat
// get file by id
export const getBusinessTripCahsFileById = createAsyncThunk(
  "getBusinessTripCahsFileById",
  async ({ id, user_id }) => {
    try {
      const { data, status } = await axiosRequest.get(
        `accounting/v1/businessTrip/download/certificate/?_id=${id}&user_id=${user_id}`,
        {
          responseType: "blob",
        }
      );

      if (status === 404) {
        console.error("Файл не найден на сервере");
        return;
      }

      handleBinaryData(data, "file.pdf", "application/pdf");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const updateBusinessTripCahsApprove = createAsyncThunk(
  "BusinessTripCahs/updateApproveStatus",
  async ({ id, user_id }, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.get(
        `accounting/v1/businessTrip/approve/?_id=${id}&user_id=${user_id}`
      );
      return data; // Возвращаем данные для обновления состояния
    } catch (error) {
      console.error("Ошибка при обновлении статуса командировки:", error);
      return rejectWithValue(error.response?.data || "Ошибка сервера");
    }
  }
);

export const getWarehouseByNumberName = createAsyncThunk(
  "getWarehouseByNumberName",
  async ({ number, name }, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.get(
        `accounting/v1/warehouse/order/?name=${name}&number=${number}`
      );
      if (data?.msg) {
        message.error(data.msg);
      }
      return data;
    } catch (error) {
      console.error("Ошибка при обновлении статуса командировки:", error);
      return rejectWithValue(error.response?.data || "Ошибка сервера");
    }
  }
);
export const getIncomingByWnumCid = createAsyncThunk(
  "getIncomingByWnumCid",
  async ({ waybill_number, counterparty_id }, { rejectWithValue }) => {
    try {
      const params = new URLSearchParams();
      if (waybill_number) params.append("waybill_number", waybill_number);
      if (counterparty_id) params.append("counterparty_id", counterparty_id);
      const { data } = await axiosRequest.get(
        `accounting/v1/warehouse/incoming/?${params}`
      );
      return data;
    } catch (error) {
      console.error("Ошибка при обновлении статуса командировки:", error);
      return rejectWithValue(error.response?.data || "Ошибка сервера");
    }
  }
);

export const postIncoming = createAsyncThunk(
  "postIncoming",
  async ({ body }, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.post(
        `accounting/v1/warehouse/incoming`,
        body
      );
      return data;
    } catch (error) {
      console.error("Ошибка при отправке данных:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const postWarehouseStat = createAsyncThunk(
  "postWarehouseStat",
  async ({ body }, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.post(
        `accounting/v1/warehouse/stat`,
        body
      );

      return data;
    } catch (error) {
      console.error("Ошибка при отправке данных:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getWarehouseDisposal = createAsyncThunk(
  "getWarehouseDisposal",
  async ({ staff_id = null, name_id }, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.get(
        `accounting/v1/warehouse/disposal?staff=${staff_id}&name_id=${name_id}`
      );

      return data;
    } catch (error) {
      console.error("Ошибка при отправке данных:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const postBid = createAsyncThunk(
  "postBid",
  async (dataBid, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.post(
        `accounting/v1/warehouse/order`,
        dataBid // Передаем объект напрямую
      );

      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.error("Ошибка при отправке данных:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
