//////////////////////       Swagger AUTH          //////////////////////

import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosBaseRequest } from "../../../utils/axiosBaseUrl";
import { handleBinaryData } from "../../../utils/globalFunction";
import { axiosRequest } from "../../../utils/axiosURL";

// document sing
export const documentSign = createAsyncThunk(
  "documentSign",
  async (body, { dispatch }) => {
    try {
      const { data } = await axiosBaseRequest.post(
        `storage/v1/file/document_sign`,
        body
      );
      console.log("data", data);
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get document by id
export const getDocById = createAsyncThunk(
  "getDocById",
  async (body, { dispatch }) => {
    try {
      const { data } = await axiosBaseRequest.get(
        `storage/v1/files?doc_id=${body.doc_id}`
      );
      // if (body.print) {
      //   data.files?.map((el) => {
      //     dispatch(getFileById({ file_id: data.file_id }));
      //   })
      // }
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get file by id
export const getFileById = createAsyncThunk(
  "getFileById",
  async ({ file_id, download = true }) => {
    try {
      const { data } = await axiosBaseRequest.get(
        `storage/v1/file?file_id=${file_id}`,
        {
          responseType: "blob",
        }
      );
      if (download) {
        handleBinaryData(data, "file.pdf", "application/pdf");
      }
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get file by id
export const documentVerify = createAsyncThunk(
  "documentVerify",
  async ({ file_id }) => {
    try {
      const { data } = await axiosBaseRequest.post(
        `/storage/v1/file/document_verify`,
        {
          file_id: file_id,
        }
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// put file by id
export const putFileById = createAsyncThunk(
  "putFileById",
  async ({ body }, { dispatch }) => {
    try {
      const { data } = await axiosRequest.put(`storage/v1/file`, body);
      dispatch(getDocById({ doc_id: data.doc_id }));
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);
