import axios from "axios";
import { destroyToken } from "./Token";
import { encruption } from "./encruption";
import i18n from "i18next";
import toast from "react-hot-toast";

const API_URL = process.env.REACT_APP_API_URL;
const TIMEOUT = 10000;

// Create an Axios instance
const axiosRequest = axios.create({
  baseURL: API_URL,
  timeout: TIMEOUT,
  headers: {
    common: {
      "X-APP-TOKEN": encruption(),
    },
    get: {
      "X-APP-TOKEN": encruption(),
    },
    post: {
      "X-APP-TOKEN": encruption(),
    },
    put: {
      "X-APP-TOKEN": encruption(),
    },
    delete: {
      "X-APP-TOKEN": encruption(),
    },
    patch: {
      "X-APP-TOKEN": encruption(),
    },
  },
});

// Request interceptor to add Authorization header
axiosRequest.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle errors and token refresh
axiosRequest.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config, response, request } = error;
    const status = response?.status || "unknown";
    const originalRequest = config;

    const errorMessageMap = {
      401: "error.unauthorized",
      403: "error.unauthorizedUser",
      404: "error.notFound",
      409: "error.conflict",
      422: "error.unprocessableEntity",
      500: "error.serverError",
      502: "error.badGateway",
      504: "error.gatewayTimeout",
      default: "error.default",
    };

    // Используем i18n для перевода
    const userMessage = i18n.t(
      errorMessageMap[status] || errorMessageMap.default
    );

    // Показываем сообщение пользователю
    toast.custom((t) => (
      <div
        className={`${
          t.visible ? "animate-enter duration-20" : "animate-leave duration-20"
        } max-w-md w-full mx-72 bg-white shadow-lg rounded-lg pointer-events-auto flex flex-wrap justify-center items-center ring-1 ring-black ring-opacity-5`}
      >
        <div className="flex-1 w-full p-4">
          <div className="flex justify-center items-center">
            <div className="mx-3 flex-1">
              <p className="text-sm font-medium text-red-500">{userMessage}</p>
              <details>
                <summary className="flex cursor-pointer text-blue-600">
                  <p className="mt-1 text-sm text-blue-600 opacity-55">
                    {i18n.t("error.details")}
                  </p>
                </summary>
                <p className="w-[250px] mx-2 text-xs">
                  {JSON.stringify(
                    {
                      status: response?.status,
                      data: response?.data,
                      request: request?.responseURL,
                    },
                    null,
                    2
                  )}
                </p>
              </details>
            </div>
          </div>
        </div>
        <div className=" " onClick={() => toast.dismiss(t.id)}>
          <button
            onClick={() => toast.dismiss(t.id)}
            className="w-full p-5 flex items-center justify-center text-sm font-medium  text-indigo-600 hover:text-indigo-500 focus:text-green-500"
          >
            {i18n.t("error.closeInfo")}
          </button>
        </div>
      </div>
    ));

    // Обработка логики токена
    if (status === 401) {
      try {
        const refreshResponse = await axios.get(
          `${API_URL}auth/v1/refresh_token`,
          {
            headers: {
              "X-APP-TOKEN": encruption(),
            },
          }
        );
        if (refreshResponse.status === 401) {
          destroyToken();
          window.location.href = "/login";
          return Promise.reject(new Error("Unauthorized"));
        }
        localStorage.setItem("token", refreshResponse.data.access_token);
        originalRequest.headers.Authorization = `Bearer ${refreshResponse.data.access_token}`;
        return axios(originalRequest);
      } catch (err) {
        destroyToken();
        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  }
);

export { axiosRequest };
