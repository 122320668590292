//////////////////////       Swagger PENSION          //////////////////////

import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosRequest } from "../../../utils/axiosURL";
import { message } from "antd";

// pension #########################

// upsert pension acounting
export const upsertPensionAccounting = createAsyncThunk(
  "upsertPensionAccounting",
  async (body) => {
    try {
      const { data } = await axiosRequest.post("pension/v1/pension", body, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get pension accounting by id
export const getPensionAccountingById = createAsyncThunk(
  "getPensionAccountingById",
  async ({ staff_id, days }) => {
    try {
      const { data } = await axiosRequest.get(
        `pension/v1/pension?staff_id=${staff_id}&days=${days}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get pensions
export const getPensioners = createAsyncThunk(
  "getPensioners",
  async ({ type_id, department_id }) => {
    try {
      const params = new URLSearchParams();
      if (type_id) params.append("type_id", type_id);
      if (department_id) params.append("department_id", department_id);
      const { data } = await axiosRequest.get(
        `pension/v1/pension/all?${params}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const getPensionDiedOrDisableStaffs = createAsyncThunk(
  "getPensionDiedOrDisableStaffs",
  async ({ type_id }) => {
    try {
      const { data } = await axiosRequest.get(
        `pension/v1/pension/died_or_disabled_staffs?type_id=${type_id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);
export const getPensionOtherStaffData = createAsyncThunk(
  "getPensionOtherStaffData",
  async ({ staff_id, department_id }) => {
    try {
      const { data } = await axiosRequest.get(
        `pension/v1/pension/other_staff_data?staff_id=${staff_id}&department_id=${department_id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);
