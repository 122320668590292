import React, { useState } from "react";

function PDFViewer({ pdfBlob }) {
  const [pdfUrl, setPdfUrl] = useState(null);

  React.useEffect(() => {
    if (pdfBlob) {
      const url = URL.createObjectURL(pdfBlob);
      setPdfUrl(url);
      return () => URL.revokeObjectURL(url);
    }
  }, [pdfBlob]);

  return (
    <div>
      {pdfUrl ? (
        <iframe
          src={pdfUrl}
          width="100%"
          height="600px"
          title="PDF Viewer"
          style={{ border: "none" }}
        ></iframe>
      ) : (
        <p>Загрузка PDF...</p>
      )}
    </div>
  );
}

export default PDFViewer;
