import {
  // createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
// import { axiosRequest } from "../../utils/axiosURL";
import { MvdFeuLocalStorageStateExtraReducers } from "./extraReducers/MvdFeuLocalStorageStateExtraReducers";
import { referenceBookItems, warehouseItems } from "../../constanst/const";

const initialState = {
  // Query states
  loading: false,
  error: false,

  // User states
  user: {},
  userIsOtp: false,
  language: "tj",
  userAcl: [],
  userOtp: false,
  userHasOtp: false,

  // modules state
  moduleStoSta: {},
  userAccesses: {},

  departmentIdLSS: null,
  referenceBookItem: referenceBookItems[0],
  warehouseItem: warehouseItems[0],
  pensionAccountingItem: "3",
  departmentParentId: null,
};

const MvdFeuLocalStorageState = createSlice({
  name: "MvdFeuLocalStorageState",
  initialState,
  reducers: {
    // User func.
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserOtp: (state, action) => {
      state.userOtp = action.payload;
    },
    setUserHasOtp: (state, action) => {
      state.userHasOtp = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setModuleStoSta: (state, action) => {
      state.moduleStoSta = action.payload;
    },
    setUserAccesses: (state, action) => {
      state.userAccesses = action.payload;
    },
    setDepartmentIdLSS: (state, action) => {
      state.departmentIdLSS = action.payload;
    },
    setDepartmentParentId: (state, action) => {
      state.departmentParentId = action.payload;
    },
    setReferenceBookItem: (state, action) => {
      state.referenceBookItem = action.payload;
    },
    setWarehouseItem: (state, action) => {
      state.warehouseItem = action.payload;
    },
    setPensionAccountingItem: (state, action) => {
      state.pensionAccountingItem = action.payload;
    },
  },
  extraReducers: MvdFeuLocalStorageStateExtraReducers,
});

export const {
  setUser,
  setUserOtp,
  setUserHasOtp,
  setLanguage,
  setModuleStoSta,
  setUserAccesses,
  setDepartmentIdLSS,
  setReferenceBookItem,
  setDepartmentParentId,
  setTaransactionSalaryIntermediateDocReader,
  setWarehouseItem,
  setPensionAccountingItem,
} = MvdFeuLocalStorageState.actions;
export default MvdFeuLocalStorageState.reducer;
