import { Viewer, Worker } from "@react-pdf-viewer/core";
import React from "react";
import "@react-pdf-viewer/core/lib/styles/index.css";

function PDFViewer({ pdfBlob }) {
  const pdfBlobUrl = URL.createObjectURL(pdfBlob);
  return (
    <div className="w-[100%] h-[600px]">
      <Worker
        workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`}
      >
        <Viewer fileUrl={pdfBlobUrl} />
      </Worker>
    </div>
  );
}

export default PDFViewer;
