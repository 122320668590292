//////////////////////       Swagger AUTH          //////////////////////

import { createAsyncThunk } from "@reduxjs/toolkit";
import { destroyToken, saveToken } from "../../../utils/Token";
import { axiosBaseRequest } from "../../../utils/axiosBaseUrl";
import { axiosRequest } from "../../../utils/axiosURL";
import { message } from "antd";
import { setOtpModal } from "../globalState";
import { setUserHasOtp, setUserOtp } from "../MvdFeuLocalStorageState";

// check login
export const checkLogin = createAsyncThunk(
  "checkLogin",
  async (body, { dispatch }) => {
    try {
      const { data } = await axiosBaseRequest.post("auth/v1/login/check", body);
      if (data?.success && !data?.otp_enabled) {
        dispatch(loginUser({ ...body, otp: "" }));
        dispatch(setUserOtp(false));
      } else {
        dispatch(
          setOtpModal({
            open: true,
            details: {
              qrcode: false,
              onSave: (otp) => {
                dispatch(loginUser({ ...body, otp }));
              },
            },
          })
        );
        dispatch(setUserOtp(true));
        // dispatch(loginUser({ ...body, otp: "529509" }));
      }
      return { data, body };
    } catch (error) {
      if (error.response?.status === 401) {
        message.error("Неправильный логин или пароль");
      }
      console.log("error", error);
    }
  }
);

// autorization
export const loginUser = createAsyncThunk(
  "loginUser",
  async (body, { dispatch }) => {
    try {
      const response = await axiosBaseRequest.post("auth/v1/login", body);
      console.log(response);
      if (response.status !== 401) {
        saveToken(response.data?.access_token);
        window.location.href = "/";
        dispatch(setUserHasOtp(body?.otp?.length === 6));
      }
      return response;
    } catch (error) {
      if (error.response?.status === 401) {
        message.error("Неправильный КОД");
      }
      console.log("error", error);
    }
  }
);

// check login
export const otpGenerate = createAsyncThunk("otpGenerate", async (_) => {
  try {
    const { data } = await axiosRequest.post("auth/v1/otp/generate");
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

// check login
export const otpConfirm = createAsyncThunk(
  "otpConfirm",
  async (body, { dispatch }) => {
    try {
      const { data } = await axiosRequest.post("auth/v1/otp/confirm", body);
      if (data.success) {
        message.success("2FA успешно привязан!");
        destroyToken();
      }
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// refresh token
export const refreshToken = createAsyncThunk(
  "refreshToken",
  async ({ onQuery = () => {} }) => {
    try {
      const response = await axiosBaseRequest.get("auth/v1/refresh_token");
      if (response.status !== 401) {
        saveToken(response.data?.access_token);
        onQuery();
      }
      return response;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// reset password
export const resetPassword = createAsyncThunk("resetPassword", async (body) => {
  try {
    const { data } = await axiosRequest.post("auth/v1/password/reset", body);
    return data;
  } catch (error) {
    console.log("error", error);
  }
});
