//////////////////////       Swagger AUTH          //////////////////////

import { createAsyncThunk } from "@reduxjs/toolkit";
import { saveToken } from "../../../utils/Token";
import { axiosBaseRequest } from "../../../utils/axiosBaseUrl";
import { axiosRequest } from "../../../utils/axiosURL";
import { message } from "antd";
import { setOtpModal } from "../globalState";
import { setUserOtp } from "../MvdFeuLocalStorageState";

// check login
export const checkLogin = createAsyncThunk(
  "checkLogin",
  async (body, { dispatch }) => {
    try {
      const { data } = await axiosBaseRequest.post("auth/v1/login/check", body);
      // if(data)
      console.log("data", data);
      if (data?.success && !data?.otp_enabled) {
        dispatch(loginUser({ ...body, otp: "" }));
        dispatch(setUserOtp(false))
      } else {
        console.log("body else", body);
        dispatch(
          setOtpModal({
            open: true,
            details: {
              qrcode: false,
              onSave: (otp) => {
                console.log("otp 2", otp);
                dispatch(loginUser({ ...body, otp }));
              },
            },
          })
        );
        dispatch(setUserOtp(true))
        // dispatch(loginUser({ ...body, otp: "529509" }));
      }
      return { data, body };
    } catch (error) {
      console.log("error", error);
    }
  }
);

// autorization
export const loginUser = createAsyncThunk("loginUser", async (body) => {
  try {
    const response = await axiosBaseRequest.post("auth/v1/login", body);
    console.log(JSON.stringify(response));
    if (response.status !== 401) {
      saveToken(response.data?.access_token);
      window.location.href = "/";
    } else {
      console.log(response);
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
});

// check login
export const otpGenerate = createAsyncThunk("otpGenerate", async (_) => {
  try {
    const { data } = await axiosRequest.post("auth/v1/otp/generate");
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

// check login
export const otpConfirm = createAsyncThunk(
  "otpConfirm",
  async (body, { dispatch }) => {
    try {
      const { data } = await axiosRequest.post("auth/v1/otp/confirm", body);
      if (data.success) {
        message.success("2FA успешно привязан!");
      }
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// refresh token
export const refreshToken = createAsyncThunk(
  "refreshToken",
  async ({ onQuery = () => {} }) => {
    try {
      const response = await axiosBaseRequest.get("auth/v1/refresh_token");
      if (response.status !== 401) {
        saveToken(response.data?.access_token);
        onQuery();
      } else {
        console.log(response);
      }
      return response;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// reset password
export const resetPassword = createAsyncThunk("resetPassword", async (body) => {
  try {
    const { data } = await axiosRequest.post("auth/v1/password/reset", body);
    return data;
  } catch (error) {
    console.log("error", error);
  }
});
