import { useEffect, useState } from "react";
import { data, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../redux/reducers/globalStateQueries/AdminQueries";
import { Select } from "antd";

function WebSocketCom() {
  const dispatch = useDispatch();
  const users = useSelector(({ globalState }) => globalState.users);
  const user = useSelector(
    ({ MvdFeuLocalStorageState }) => MvdFeuLocalStorageState.user
  );
  const [notifications, setNotifications] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [noteReceiver, setNoteReceiver] = useState({
    id: null,
    notification: null,
  });
  const [ws, setWs] = useState(null);
  const [toast, setToast] = useState(false);
  const navigate = useNavigate();
  const jwt = JSON.parse(localStorage.getItem("jwt"));

  useEffect(() => {
    if (user?.signer || true) {
      let _ws;

      try {
        _ws = new WebSocket(
          `wss://devfeu.vkdtj.info/api/notification/v1/websocket/connection/${user?.user_id}`
        );

        _ws.onopen = () => {
          console.log("WebSocket connection established.");
          setWs(_ws);
        };

        _ws.onmessage = (event) => {
          try {
            const res = JSON.parse(event.data);

            if (res.data === null || res.data.status === "error") return;

            setNotifications((p) => [
              ...p,
              ...(Array.isArray(res.data) ? res.data : [res.data]),
            ]);
            setToast(Array.isArray(res.data) ? false : true);
          } catch (error) {
            console.error("Error parsing WebSocket message:", error);
          }
        };

        _ws.onerror = (error) => {
          console.error("WebSocket error:", error);
        };

        _ws.onclose = () => {
          console.log("WebSocket connection closed.");
        };
      } catch (error) {
        console.error("ERROR while connecting to the WebSocket:", error);
      }

      return () => {
        if (ws !== null) {
          ws.close();
        }
      };
    }
  }, []);

  // const RealTimeFun = () => {
  //   const evtSource = new EventSource(
  //     `ws://192.168.1.122:8000/accounting/websocket/connection/${user?.user_id}`
  //   );

  //   evtSource.onmessage = (event) => {
  //     // setRealTime(JSON.parse(event.data));
  //     console.log(JSON.parse(event.data));
  //     // soundPlay();
  //   };

  //   // console.log(realTime);
  // };

  // useEffect(() => {
  //   RealTimeFun();
  // }, []);

  async function handleSendNotification() {
    if (noteReceiver.id === null || noteReceiver.notification === null) {
      alert("Please select a user and write a message to send notification!");
      return;
    }

    if (!ws || ws.readyState !== WebSocket.OPEN) {
      alert("WebSocket is not connected.");
      return;
    }

    try {
      const message = JSON.stringify({
        sender: user?.user_id,
        receiver: noteReceiver.id,
        notification: noteReceiver.notification,
        read: false,
      });

      const res = ws.send(message);
      if (res !== undefined && res.status === "error")
        throw new Error(res.message);
    } catch (error) {
      console.error("ERROR while connecting to the websocket: ", error);
    }
  }

  function handleLogout() {
    localStorage.removeItem("jwt");
    ws.close();
    navigate("/login");
  }

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  return (
    <div className="App">
      {/* NOTIFICATION BANNER */}
      {notifications.length > 0 && (
        <div
          id="toast-default"
          className={`${
            toast ? "flex" : "hidden"
          } items-center w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-700 fixed right-5 top-20`}
          role="alert"
        >
          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-blue-500 bg-blue-100 rounded-lg dark:bg-green-800 dark:text-blue-200">
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
            </svg>
            <span className="sr-only">Fire icon</span>
          </div>
          <div className="ms-3 text-sm font-normal">
            {notifications[notifications.length - 1].notification}
          </div>
          <button
            type="button"
            className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
            data-dismiss-target="#toast-default"
            aria-label="Close"
            onClick={() => setToast(false)}
          >
            <span className="sr-only">Close</span>
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
          </button>
        </div>
      )}

      {/* HEADER */}
      <div className="bg-gray-800 text-white p-4 flex justify-between items-center">
        <div className="text-lg font-bold">My App</div>

        <h1>
          {user?.lastname || ""} {user?.firstname || ""}{" "}
          {user?.middlename || ""}
        </h1>

        <div className="flex items-center space-x-4">
          <button
            className="bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded"
            onClick={() => handleLogout()}
          >
            Logout
          </button>

          <div className="relative">
            <button
              className={`${
                notifications.length > 0
                  ? "bg-blue-600 hover:bg-blue-700"
                  : "bg-slate-600 hover:bg-red-700"
              } text-white font-semibold py-2 px-4 rounded`}
              onClick={() => setDropdownOpen((p) => !p)}
            >
              Notifications
            </button>

            <div
              id="dropdown"
              className={`absolute right-0 mt-2 w-64 bg-white text-black shadow-lg rounded-lg ${
                dropdownOpen ? "block" : "hidden"
              }`}
            >
              <div className="p-4 border-b">
                <span className="font-bold">Active Notifications</span>
              </div>
              <ul className="divide-y">
                {notifications.map((notification, i) => (
                  <li key={i} className="p-4 hover:bg-gray-100 cursor-pointer">
                    {notification.notification +
                      ", " +
                      new Date(notification.sent_time)
                        .toISOString()
                        .split("T")[0]}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* PAGE */}
      <div className="p-6 max-w-lg mx-auto bg-white shadow-md rounded-lg">
        <div className="mb-4">
          <label
            htmlFor="user"
            className="block text-sm font-medium text-gray-700"
          >
            Select User
          </label>
          <Select options={users.map((user) => ({label:""}))}/>
          <select
            onChange={(e) => {
              const user = users?.find((i) => i.username === e.target.value);
              setNoteReceiver((p) => ({ ...p, id: user.user_id }));
            }}
            id="user"
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">-- Select a User --</option>
            {users?.map((user, i) => (
              <option key={i} value={user.username}>
                {user.username}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label
            htmlFor="message"
            className="block text-sm font-medium text-gray-700"
          >
            Message
          </label>
          <textarea
            id="message"
            rows="4"
            onChange={(e) => {
              setNoteReceiver((p) => ({ ...p, notification: e.target.value }));
            }}
            className="mt-1 block w-full px-3 py-2 text-gray-700 border border-gray-300 rounded-lg focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="Write your message here..."
          ></textarea>
        </div>

        <button
          id="sendNotification"
          onClick={handleSendNotification}
          className="w-full py-2 px-4 bg-indigo-600 text-white font-semibold rounded-lg shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Send Notification
        </button>
      </div>
    </div>
  );
}

export default WebSocketCom;
