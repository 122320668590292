//////////////////////       Swagger HR          //////////////////////

import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosRequest } from "../../../utils/axiosURL";
import { message } from "antd";
import { setDepartmentIdLSS } from "../MvdFeuLocalStorageState";
import { phoneNumberFormater } from "../../../utils/globalFunction";
import { documentSignFetch, getFileById } from "./StorageQueries";

// Hiring #########################

// ------- Staff queries -------
// create or update staff
export const createOrUpdateStaff = createAsyncThunk(
  "createOrUpdateStaff",
  async (
    { staff, lengthOfService, user_id, account_number = null },
    { dispatch }
  ) => {
    try {
      const details = {
        ...staff.details,
        passport: {
          authority: staff.details.passport.authority,
          doc_number: staff.details.passport.doc_number,
          issued:
            typeof staff.details.passport.issued == "string"
              ? staff.details.passport.issued
              : staff.details.passport.issued.dateStr,
          expired:
            typeof staff.details.passport.expired == "string"
              ? staff.details.passport.expired
              : staff.details.passport.expired.dateStr,
        },
      };

      const formData = new FormData();
      formData.append("firstname", staff.firstname);
      formData.append("lastname", staff.lastname);
      formData.append(
        "dob",
        typeof staff.dob == "string" ? staff.dob : staff.dob.dateStr
      );
      formData.append("phone", phoneNumberFormater(staff.phone));
      formData.append("tin", staff.tin);
      formData.append("ident_number", staff.ident_number);
      formData.append("gender", staff.gender);
      formData.append("user_id", user_id);
      if (staff?.fileList?.length) {
        for (let i = 0; i < staff.fileList.length; i++) {
          formData.append("documents", staff.fileList[i].originFileObj);
          formData.append("document_types", 17);
          formData.append(
            "document_meta",
            JSON.stringify({
              doc_number: staff.details.passport.doc_number,
            })
          );
        }
      } else {
        formData.append("doc_id", staff.doc_id || 49);
      }
      formData.append("details", JSON.stringify(details));
      formData.append("middlename", staff.middlename || "");
      if (staff.id) formData.append("staff_id", staff.id);
      const { data } = await axiosRequest.post("hr/v1/staff", formData);
      let department_id;
      if (!staff.id) {
        for (let i = 0; i < lengthOfService.length; i++) {
          const formData = new FormData();
          let el = lengthOfService[i];
          if (el?.fileList && el.fileList?.length) {
            for (let j = 0; j < el.fileList.length; j++) {
              formData.append("documents", el.fileList[j].originFileObj); //el.file.fileList[0].originFileObj
              formData.append("document_types", el.doc_type);
              formData.append(
                "document_meta",
                JSON.stringify({ order_number: el.order_number }) || "{}"
              );
            }
          }
          formData.append("staff_id", +data.id);
          formData.append("region_id", el.region_id);
          formData.append("organization", el.organization);
          formData.append("start_date", el.start_date.dateStr);
          formData.append("jobtitle", el.jobtitle.label);
          formData.append("department", el.department?.label);
          formData.append("is_military", el.is_military);
          formData.append(
            "jobtitle_id",
            +el.jobtitle.value === 0 ? "" : +el.jobtitle.value
          );
          formData.append(
            "jobtitle_amount_id",
            +el.jobtitle.jobtitle_amount_id === 0 ? "" : +el.jobtitle.jobtitle_amount_id
          );
          formData.append(
            "rank_id",
            el.rank_id
              ? isNaN(+el.rank_id) || +el.rank_id === 0
                ? ""
                : +el.rank_id
              : ""
          );
          formData.append("is_staff_member", el.is_staff_member);
          formData.append("end_date", el.end_date.dateStr || "");
          if (i === 0)
            department_id =
              +el.department?.value === 0 ? "" : +el.department?.value;
          formData.append(
            "department_id",
            +el.department?.value === 0 ? "" : +el.department?.value
          );
          formData.append("is_intern", el.is_intern);
          formData.append("order_number", el.order_number);
          formData.append("user_id", user_id);
          await dispatch(createOrUpdateJobposition({ body: formData }));
        }
      }
      dispatch(getStaffJobpositions({ staff_id: data.id }));
      if (account_number) {
        dispatch(
          postCardOfBank({
            staff_id: data.id,
            account_number: account_number,
            user_id: user_id,
          })
        );
      }

      if (!staff.id) {
        message.success("Пользователь создан");
      } else {
        message.success("Пользователь редактирован");
      }
      if (department_id) {
        dispatch(
          getStaffsByDepartmentId({
            department_id: department_id,
          })
        );
      }
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get staffs or staff by id
export const getStaffsOrStaffById = createAsyncThunk(
  "getStaffsOrStaffById",
  async ({ body, limit = null, offset = null, staff_id = null }) => {
    try {
      const { data } = await axiosRequest.get(
        staff_id
          ? `hr/v1/staff?staff_id=${staff_id}`
          : limit
          ? `hr/v1/staff?limit=${limit}&offset=${offset}`
          : "hr/v1/staff"
      );
      return staff_id ? data[0] : data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get staffs by department id
export const getStaffsByDepartmentId = createAsyncThunk(
  "getStaffsByDepartmentId",
  async (
    { limit = null, offset = null, department_id, parent_id = null },
    { dispatch }
  ) => {
    try {
      const { data } = await axiosRequest.get(
        limit
          ? `hr/v1/${department_id}/staff?limit=${limit}&offset=${offset}`
          : `hr/v1/${department_id}/staff`
      );
      if (!parent_id) {
        dispatch(setDepartmentIdLSS(department_id));
      }
      return { data, department_id, parent_id };
    } catch (error) {
      console.log("error", error);
    }
  }
);
// ----------------------------

// ------- Jobposition queries -------
// creat or update jobposition
export const createOrUpdateJobposition = createAsyncThunk(
  "createOrUpdateJobposition",
  async ({ body, staff_id = null }, { dispatch }) => {
    try {
      const { data } = await axiosRequest.post(
        `hr/v1/staff/jobposition`,
        body,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (staff_id) {
        dispatch(getStaffJobpositions({ staff_id: staff_id }));
      }

      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// change staff jobposition
export const changeStaffJobposition = createAsyncThunk(
  "changeStaffJobposition",
  async ({ lengthOfService, staff, user_id }, { dispatch }) => {
    try {
      const formData = new FormData();
      let el = lengthOfService[0];
      if (el.fileList?.length) {
        for (let j = 0; j < el.fileList.length; j++) {
          formData.append("documents", el.fileList[j].originFileObj);
          formData.append("document_types", el.doc_type);
          formData.append(
            "document_meta",
            JSON.stringify({ order_number: el.order_number }) || "{}"
          );
        }
      } else {
        formData.append("doc_id", el.doc_id);
      }
      formData.append("staff_id", +staff.id);
      formData.append("jobposition_id", +el.id);
      formData.append("comment", el.comment);
      formData.append("region_id", el.region_id);
      formData.append("organization", el.organization);
      formData.append(
        "start_date",
        typeof el.start_date == "string" ? el.start_date : el.start_date.dateStr
      );
      formData.append(
        "jobtitle",
        typeof el?.jobtitle == "string" ? el.jobtitle : el.jobtitle?.label
      );
      formData.append(
        "department",
        typeof el.department == "string" ? el.department : el.department?.label
      );
      formData.append("is_military", el.is_military);
      formData.append(
        "jobtitle_id",
        !el.jobtitle?.value
          ? el.jobtitle_id
          : +el.jobtitle?.value === 0
          ? ""
          : +el.jobtitle?.value
      );
      formData.append(
        "jobtitle_amount_id",
        !el.jobtitle_amount_id?.value
          ? el.jobtitle_amount_id
          : +el.jobtitle_amount_id?.value === 0
          ? ""
          : +el.jobtitle_amount_id?.value
      );
      formData.append(
        "rank_id",
        isNaN(+el.rank_id) || +el.rank_id === 0 ? "" : +el.rank_id
      );
      formData.append("is_staff_member", el.is_staff_member);
      if (el?.end_date?.dateStr) {
        formData.append("end_date", el?.end_date?.dateStr || "");
      }
      formData.append(
        "department_id",
        !el.department?.value
          ? el.department_id
          : +el.department?.value === 0
          ? ""
          : +el.department?.value
      );
      formData.append("is_intern", el.is_intern);
      formData.append("order_number", el.order_number);
      formData.append("user_id", user_id);
      const { data } = await axiosRequest.put(
        "hr/v1/staff/jobposition",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      message.success("Стаж редактирован");
      dispatch(getStaffJobpositions({ staff_id: staff.id }));
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upload jobposition file
export const uploadJobpositionFile = createAsyncThunk(
  "uploadJobpositionFile",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        "hr/v1/upload/jobposition",
        body,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get jobposition
export const getStaffJobposition = createAsyncThunk(
  "getStaffJobposition",
  async ({ staff_id }) => {
    try {
      const { data } = await axiosRequest.get(
        `hr/v1/staff/jobposition?staff_id=${staff_id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get jobpositions
export const getStaffJobpositions = createAsyncThunk(
  "getStaffJobpositions",
  async ({ staff_id }, { dispatch }) => {
    try {
      const { data } = await axiosRequest.get(
        `hr/v1/staff/jobpositions?staff_id=${staff_id}`
      );
      // dispatch(get)
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get staff salary
export const getStaffSalary = createAsyncThunk(
  "getStaffSalary",
  async ({ staff_id }) => {
    try {
      const { data } = await axiosRequest.get(
        `hr/v1/staff/salary?staff_id=${staff_id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get staff signers
export const getStaffSigners = createAsyncThunk(
  "getStaffSigners",
  async ({ department_id }) => {
    try {
      const { data } = await axiosRequest.get(
        `hr/v1/staff/signers?department_id=${department_id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// create staff managment
export const createStaffManagement = createAsyncThunk(
  "createStaffManagement",
  async ({ body, department_id }, { dispatch }) => {
    try {
      const { data } = await axiosRequest.post(`hr/v1/staff/management`, body, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      dispatch(getStaffsByDepartmentId({ department_id }));
      dispatch(getStaffsManagementByDepartmentId({ department_id }));
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get staffs or staff by id managment
export const getStaffsOrStaffByIdManagement = createAsyncThunk(
  "getStaffsOrStaffByIdManagement",
  async ({ limit = null, offset = null, staff_id = null }) => {
    try {
      const { data } = await axiosRequest.get(
        `hr/v1/staff/management${
          staff_id
            ? `?staff_id=${staff_id}`
            : limit || offset
            ? `?limit=${limit}&offset=${offset}`
            : ""
        }`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get staffs managment by department id
export const getStaffsManagementByDepartmentId = createAsyncThunk(
  "getStaffsManagementByDepartmentId",
  async (body) => {
    try {
      const { data } = await axiosRequest.get(
        `hr/v1/staff/management_byDepartment?department_id=${body.department_id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// update staff managment
export const updateStaffManagment = createAsyncThunk(
  "updateStaffManagment",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        `hr/v1/staff/management/prolongation`,
        body
      );
      message.success("Успешно сохранён");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// reinstatement staff managment
export const reinstatementStaffManagment = createAsyncThunk(
  "reinstatementStaffManagment",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        `hr/v1/staff/management/reinstatement`,
        body,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// create staff terminate
export const createStaffTerminate = createAsyncThunk(
  "createStaffTerminate",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(`hr/v1/staff/terminate`, body, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      message.success("Создан успешно");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get staffs terminate
export const getStaffsTerminate = createAsyncThunk(
  "getStaffsTerminate",
  async (body) => {
    try {
      const { data } = await axiosRequest.get(`hr/v1/staff/terminate`);
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// create staff disciplinary action
export const createStaffDisciplinaryAction = createAsyncThunk(
  "createStaffDisciplinaryAction",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        `hr/v1/staff/disciplinary_action`,
        body,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      message.success("Создан успешно");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get staffs or staff by id disciplinary action
export const getStaffsOrStaffByIdDisciplinaryAction = createAsyncThunk(
  "getStaffsOrStaffByIdDisciplinaryAction",
  async ({ staff_id = null, limit = null, offset = null }) => {
    try {
      const { data } = await axiosRequest.get(
        `hr/v1/staff/disciplinary_action${
          staff_id
            ? `?staff_id=${staff_id}`
            : limit
            ? `?limit=${limit}&offset=${offset}`
            : ""
        }`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// create staff investigation
export const createStaffInvestigation = createAsyncThunk(
  "createStaffInvestigation",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        `hr/v1/staff/investigation`,
        body,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      message.success("Создан успешно");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get staffs investigation by department id
export const getStaffsOrStaffByIdInvestigation = createAsyncThunk(
  "getStaffsOrStaffByIdInvestigation",
  async ({ staff_id = null, limit = null, offset = null }) => {
    try {
      const { data } = await axiosRequest.get(
        `hr/v1/staff/investigation${
          staff_id
            ? `?staff_id=${staff_id}`
            : limit
            ? `?limit=${limit}&offset=${offset}`
            : ""
        }`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get staffs or staff by id investigation
export const getStaffsInvestigationByDepartmentId = createAsyncThunk(
  "getStaffsInvestigationByDepartmentId",
  async (body) => {
    try {
      const { data } = await axiosRequest.get(
        `hr/v1/staff/investigation_byDepartment?department_id=${body.department_id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// staff reinstatement
export const staffReinstatement = createAsyncThunk(
  "staffReinstatement",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        `hr/v1/staff/investigation/reinstatement`,
        body
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// Document 15 #########################

// ------- Schedule queries -------
// get Schedule
export const getSchedule = createAsyncThunk(
  "getSchedule",
  async (body, { dispatch }) => {
    try {
      const { data } = await axiosRequest.post("hr/v1/schedule/get", body);
      if (data.status === 400) {
        message.error("Вы не можете редактировать закрытый месяц");
      }
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// update schedule
export const updateSchedule = createAsyncThunk(
  "updateSchedule",
  async ({ body, user_id }, { dispatch }) => {
    try {
      const scheduleData = { ...body };
      const staffs = { ...scheduleData.staffs };
      const staffsId = Object.keys(scheduleData.staffs);
      for (let i = 0; i < staffsId.length; i++) {
        const userObj = { ...staffs[staffsId[i]] };
        delete userObj.lastname;
        delete userObj.firstname;
        delete userObj.middlename;
        delete userObj.comments;
        staffs[staffsId[i]] = userObj;
        // staffs.log ={user_id}
      }
      scheduleData.log = { user_id };
      const { data } = await axiosRequest.post("hr/v1/schedule/upsert", {
        ...scheduleData,
        staffs,
      });
      message.success("Сохранено");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// close schedule month
export const finishSchedule = createAsyncThunk(
  "finishSchedule",
  async (body, { dispatch }) => {
    try {
      const { data } = await axiosRequest.post(
        "hr/v1/schedule/close_month",
        body
      );
      if (data.status === 400) {
        message.error("Месяц уже закрыт");
      } else if (data.status === 200) {
        message.success("Месяц успешно закрыт");
      }
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// add comment changed schedule
export const changeScheduleAndAddComment = createAsyncThunk(
  "changeScheduleAndAddComment",
  async ({ changeSchedules, scheduleDate, user_id }, { dispatch }) => {
    try {
      const { data } = await axiosRequest.post(
        "hr/v1/schedule/comments",
        changeSchedules
      );
      if (data.status === 200) {
        await dispatch(updateSchedule({ body: scheduleDate, user_id }));
        let getScheduleQuery = {
          ...scheduleDate,
          staffs: Object.keys(scheduleDate.staffs),
        };
        await dispatch(getSchedule(getScheduleQuery));
      }
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);
// ----------------------------

// Vacation schedule #########################

// create or update staff vacation schedule comment changed schedule
export const createOrUpdateStaffVacationSchedule = createAsyncThunk(
  "createOrUpdateStaffVacationSchedule",
  async (body) => {
    try {
      const { data } = await axiosRequest.post("hr/v1/staff/vacation", body, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get staffs vacation schedule
export const getStaffsVacationSchedule = createAsyncThunk(
  "getStaffsVacationSchedule",
  async (body) => {
    try {
      const { data } = await axiosRequest.get(
        `hr/v1/staff/department_vacation?department_id=${body.department_id}&year=${body.year}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert business trip
export const upsertBusinessTrip = createAsyncThunk(
  "upsertBusinessTrip",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(`hr/v1/business_trip`, body);
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert department business trip
export const getDepartmentBusinessTrip = createAsyncThunk(
  "getDepartmentBusinessTrip",
  async (body) => {
    try {
      const { data } = await axiosRequest.get(
        `hr/v1/business_trip/${body.department_id}/${body.year}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get staff business trip
export const getStaffBusinessTrip = createAsyncThunk(
  "getStaffBusinessTrip",
  async (body) => {
    try {
      const { data } = await axiosRequest.get(
        `hr/v1/business_trip?_id=${body.id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// Pension #########################

// create or update deceased staff
export const createOrUpdateuDeceasedStaff = createAsyncThunk(
  "createOrUpdateuDeceasedStaff",
  async ({ body, staffsId }, { dispatch }) => {
    try {
      const { data } = await axiosRequest.post(
        "hr/v1/staff/upsert_deceased",
        body,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      message.success("Запрос успешен");
      dispatch(getDeceasedStaffsOrStaffById(staffsId));
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get deceased staffs or staff by id
export const getDeceasedStaffsOrStaffById = createAsyncThunk(
  "getDeceasedStaffsOrStaffById",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        `hr/v1/staff/get_deceased`,
        body
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// create or update disabled staff
export const createOrUpdateuDisabledStaff = createAsyncThunk(
  "createOrUpdateuDisabledStaff",
  async ({ body, staffsId }, { dispatch }) => {
    try {
      const { data } = await axiosRequest.post(
        "hr/v1/staff/upsert_disabled",
        body,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      message.success("Запрос успешен");
      dispatch(getDisabledStaffsOrStaffById(staffsId));
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get disabled staffs or staff by id
export const getDisabledStaffsOrStaffById = createAsyncThunk(
  "getDisabledStaffsOrStaffById",
  async (body) => {
    try {
      console.log("body", body);
      const { data } = await axiosRequest.post(
        `hr/v1/staff/get_disabled`,
        body
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// create or update staff child
export const createOrUpdateStaffChild = createAsyncThunk(
  "createOrUpdateStaffChild",
  async (body) => {
    try {
      const { data } = await axiosRequest.post("hr/v1/staff/child", body);
      // if (data.status == 200 && data.msg == "updated") {
      //   return body;
      // }
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get disabled staff by id
export const getStaffChildByStaffId = createAsyncThunk(
  "getStaffChildByStaffId",
  async ({ id }) => {
    try {
      const { data } = await axiosRequest.get(`hr/v1/staff/child?id=${id}`);
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);
// ----------------------------

// get current jobposition
export const getCurrentJobposition = createAsyncThunk(
  "getCurrentJobposition",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        `hr/v1/staff/get_current_jobposition`,
        body
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get current jobposition
export const downloadJobpositionsCertificate = createAsyncThunk(
  "downloadJobpositionsCertificate",
  async ({ _id, user }, { dispatch }) => {
    try {
      if (!user.is_signer) {
        message.warning("У вас нет доступа для получения документа");
        return;
      }
      const { data } = await axiosRequest.get(
        `hr/v1/staff/jobpositions/download/certificate?_id=${_id}&user_id=${user.user_id}`
      );
      await documentSignFetch({
        user_id: user.user_id,
        file_id: data,
      });

      message.success("Документ подписан", 5);
      dispatch(getFileById({ file_id: data, download: true }));
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get staffs investigation by department id
export const searchTransferOfStaff = createAsyncThunk(
  "searchTransferOfStaff",
  async (params) => {
    try {
      const queryParams = new URLSearchParams();
      if (params.year) queryParams.append("year", params.year);
      if (params.month) queryParams.append("month", params.month);
      if (params.limit) queryParams.append("limit", params.limit);
      if (params.name) queryParams.append("name", params.name);
      if (params.last_name) queryParams.append("last_name", params.last_name);
      if (params.middle_name)
        queryParams.append("middle_name", params.middle_name);
      if (params.offset || params.offset === 0)
        queryParams.append("offset", params.offset);

      const queryString = queryParams.toString();

      const { data } = await axiosRequest.post(
        `hr/v1/staff/get_staff_positions/?${queryString}`,
        {
          ids: params.body,
        }
      );
      return data || [];
    } catch (error) {
      console.log("error", error);
      // return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const postCardOfBank = createAsyncThunk(
  "postCardOfBank",
  async (
    { staff_id, account_number, user_id, entity_id },
    { rejectWithValue }
  ) => {
    try {
      const queryParams = new URLSearchParams();
      if (staff_id) queryParams.append("staff_id", staff_id);
      if (account_number) queryParams.append("account_number", account_number);
      if (user_id) queryParams.append("user_id", user_id);
      if (entity_id) {
        queryParams.append("entity_id", entity_id);
      }
      const { data } = await axiosRequest.post(
        `hr/v1/staff/account_number/?${queryParams}`
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.error("Ошибка в postCardOfBank:", error); //---------------------------------------
      return rejectWithValue(error.response?.data || "Неизвестная ошибка");
    }
  }
);

export const getCardOfBank = createAsyncThunk(
  "getCardOfBank",
  async ({ staff_id, account_number, entity_id }, { rejectWithValue }) => {
    try {
      const queryParams = new URLSearchParams();
      if (staff_id) queryParams.append("staff_id", staff_id);
      if (account_number) queryParams.append("account_number", account_number);
      if (entity_id) queryParams.append("entity_id", entity_id);

      const { data } = await axiosRequest.get(
        `hr/v1/staff/account_number/?${queryParams}`
      );
      // Преобразуем dayjs в строку
      if (data?.lengthOfService) {
        data.lengthOfService = data.lengthOfService.map((item) => ({
          ...item,
          start_date: {
            ...item.start_date,
            date: item.start_date.date?.toISOString?.() || item.start_date.date,
          },
        }));
      }
      return data;
    } catch (error) {
      console.error("Ошибка в getCardOfBank:", error);
      return rejectWithValue(error.response?.data || "Неизвестная ошибка");
    }
  }
);

export const checkCardOfBank = async ({
  staff_id,
  account_number,
  entity_id,
}) => {
  try {
    const queryParams = new URLSearchParams();

    if (staff_id) {
      queryParams.append("staff_id", staff_id);
    }

    if (account_number) {
      queryParams.append("account_number", account_number);
    }

    if (entity_id) {
      queryParams.append("entity_id", entity_id);
    }

    const { data } = await axiosRequest.get(
      `hr/v1/staff/account_number/?${queryParams}`
    );

    // Преобразуем dayjs в строку
    if (data?.lengthOfService) {
      data.lengthOfService = data.lengthOfService.map((item) => ({
        ...item,
        start_date: {
          ...item.start_date,
          date: item.start_date.date?.toISOString?.() || item.start_date.date,
        },
      }));
    }

    return data;
  } catch (error) {
    console.error("Ошибка:", error);
    return error;
  }
};

export const readNotification = createAsyncThunk(
  "readNotification",
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.get(
        `hr/v1/notification/read?id=${id}`
      );
      return { data, id };
    } catch (error) {
      console.error("Ошибка:", error);
      return rejectWithValue(error.response?.data || "Неизвестная ошибка");
    }
  }
);

export const getNotificationHistory = createAsyncThunk(
  "getNotificationHistory",
  async ({ id, limit, offset }, { rejectWithValue }) => {
    try {
      const params = new URLSearchParams();
      params.append("id", id);
      params.append("limit", limit);
      params.append("offset", offset);

      const { data } = await axiosRequest.get(
        `hr/v1/notification/all?${params}`
      );
      return { data: data.data, offset };
    } catch (error) {
      console.error("Ошибка:", error);
      return rejectWithValue(error.response?.data || "Неизвестная ошибка");
    }
  }
);
