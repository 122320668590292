import { createSlice } from "@reduxjs/toolkit";
import {
  defaultLengthOfService,
  defaultRankOptionObj,
  defaultStaff,
  defaultUnitsObj,
} from "../../constanst/const";
import { globalStateExtraReducers } from "./extraReducers/globalStateExtraReducers";

const initialState = {
  // Query states
  loading: false,
  error: false,

  // Left menu states
  leftMenu: { open: true },
  leftMenuDrawer: { open: false },
  selectedStaffs: [],
  leftMenyStates: {
    activeCheckbox: false,
  },

  // topHeader states
  dropDown: {
    open: -1,
    openChapters: -1,
  },

  // modals
  modalDoc15: { open: false },
  precautionaryModal: { open: false },
  messageModal: { open: false, message: "" },
  warrningModal: { open: false, onClick: null, message: "", title: "" },
  managmentModal: { open: false, details: null },
  addStaffVacationModal: { open: false, details: null },
  addStaffChildModal: { open: false, details: null },
  aclModal: { open: false, details: null },
  docsModal: { open: false, details: null },
  putDocModal: { open: false, details: null },
  rankbonusModal: { open: false, details: null },
  jobtitleAmountModal: { open: false, details: null },
  staffJobpositionModal: { open: false, details: null },
  otpModal: { open: false, details: null },
  cashSalaryEarlyReceiptModal: { open: false, details: {} },
  reinstatementStaffManagmentModal: { open: false, details: {} },
  defectsModal: { open: false, details: {} },
  showPensionData: { open: false, details: {} },
  upsertCommonModal: { open: false, details: {} },

  // hiring
  lengthOfService: [
    { ...defaultLengthOfService, is_military: true, organization: "ВКД" },
    // { ...defaultLengthOfService, id: Math.random() },
  ],
  staff: defaultStaff,
  staffs: [],
  jobposition: {},
  jobpositions: [],
  staffManagment: {},
  staffsManagment: [],
  staffChildrens: [],
  staffCardOfBank: [],

  // selector options
  departmentOption: [],
  departmentOptionObj: {},
  allowedDepartments: [],
  rankOption: [],
  rankOptionObj: defaultRankOptionObj,
  markerOption: [],
  jobtitleOption: [],
  jobtitleOptionObj: {},
  retentionsOption: [],
  retentions: [],
  additionalPaymentOption: [],
  additionalPayments: [],
  monthDate: [],
  genderOption: [],
  vacationOption: [],
  dismissalTypes: [],
  documentTypes: [],
  tripDailyPayOptions: [],
  countries: [],
  roleOption: [],
  region: [],
  aclAdmin: [],
  pensionAwards: [],

  calendarYear: {},
  disciplinaryActionOption: [],

  staffStatus: [],
  staffsTerminate: [],
  staffsInvestigation: [],
  staffInvestigation: {},
  staffsDisciplinaryAction: [],
  staffDisciplinaryAction: {},
  Namenclatura: [],
  NamenclaturaObj: {},
  Units: [],
  UnitsObj: defaultUnitsObj,

  // pension options
  pensionTypes: [],
  deceasedStaffGroups: [],
  disabledStaffGroups: [],
  staffFinancialAid: [],

  departmentId: null,

  documentPDf: {},

  documentVerifyName: {},

  // electronic time sheet
  scheduleDate: {},
  activeScheduleChange: { active: false, comment: null },
  changeSchedules: [],

  // vacation schedule
  staffVacation: {},
  staffsVacations: [],

  // validations
  staffValidationState: { error: false, label: null },

  // pension
  disabledStaffs: [],
  deceasedStaffs: [],
  pensionAccountings: [],
  pensioners: [],

  departmentBusinessTrip: [],
  staffBusinessTrip: [],
  modules: [],
  users: [],
  docData: {},
  taransactionSalaryIntermediate: [],
  taransactionSalaryIntermediateDoc: null,
  taransactionSalaryIntermediateBankData: null,
  aclAdminByRoleId: [],

  jobtitleAmount: [],
  rankbonus: [],

  // accounting
  cashSER: [],
  approvedSER: [],
  rejectedSER: [],
  staffChashId: [],
  otp_url: null,
  accountingAgreementsData: [],
  accountingOutgoingData: [],
  accountingOutgoingSearch: {},
  currentJobpositionData: [],
  searchTransferOfStaffdata: [],

  // warehouse
  disposalData: [],
  WarehouseSearch: {},
  accountingWarehouseDefects: {},
  WareHouseStat: [],
  WarehouseDisposal: [],
  IncomingSearch: [],
  staffWarehouseDeffects: [],

  // permissions
  permissionToWrite: false,

  // category
  category: [],

  // form
  formState: {},
  refresh: 0,
};

const globalState = createSlice({
  name: "globalState",
  initialState,
  reducers: {
    // Left menu func.
    setLeftMenu: (state, action) => {
      state.leftMenu = action.payload;
    },
    setLeftMenuDrawer: (state, action) => {
      state.leftMenuDrawer = action.payload;
    },
    setSelectedStaffs: (state, action) => {
      state.selectedStaffs = action.payload;
    },
    setLeftMenyStates: (state, action) => {
      state.leftMenyStates = action.payload;
    },

    // topHeader func.
    setDropDown: (state, action) => {
      state.dropDown = action.payload;
    },

    // modals
    setModalDoc15: (state, action) => {
      state.modalDoc15 = action.payload;
    },
    setPrecautionaryModal: (state, action) => {
      state.precautionaryModal = action.payload;
    },
    setMessageModal: (state, action) => {
      state.messageModal = action.payload;
    },
    setWarrningModal: (state, action) => {
      state.warrningModal = action.payload;
    },
    setManagmentModal: (state, action) => {
      state.managmentModal = action.payload;
    },
    setAddStaffVacationModal: (state, action) => {
      state.addStaffVacationModal = action.payload;
    },
    setAddStaffChildModal: (state, action) => {
      state.addStaffChildModal = action.payload;
    },
    setAclModal: (state, action) => {
      state.aclModal = action.payload;
    },
    setDocsModal: (state, action) => {
      state.docsModal = action.payload;
    },
    setPutDocModal: (state, action) => {
      state.putDocModal = action.payload;
    },
    setRankbonusModal: (state, action) => {
      state.rankbonusModal = action.payload;
    },
    setJobtitleAmountModal: (state, action) => {
      state.jobtitleAmountModal = action.payload;
    },
    setStaffJobpositionModal: (state, action) => {
      state.staffJobpositionModal = action.payload;
    },
    setOtpModal: (state, action) => {
      state.otpModal = action.payload;
    },
    setCashSalaryEarlyReceiptModal: (state, action) => {
      state.cashSalaryEarlyReceiptModal = action.payload;
    },
    setReinstatementStaffManagmentModal: (state, action) => {
      state.reinstatementStaffManagmentModal = action.payload;
    },
    setDefectsModal: (state, action) => {
      state.defectsModal = action.payload;
    },
    setShowPensionData: (state, action) => {
      state.showPensionData = action.payload;
    },
    setUpsertCommonModal: (state, action) => {
      state.upsertCommonModal = action.payload;
    },

    // hiring
    setLengthOfService: (state, action) => {
      state.lengthOfService = action.payload;
    },
    setStaff: (state, action) => {
      state.staff = action.payload;
    },
    setStaffs: (state, action) => {
      state.staffs = action.payload;
    },
    clearStaff: (state, action) => {
      state.staff = defaultStaff;
    },
    setJobposition: (state, action) => {
      state.jobposition = action.payload;
    },
    setJobpositions: (state, action) => {
      state.jobpositions = action.payload;
    },

    // electronic time sheet
    setScheduleDate: (state, action) => {
      state.scheduleDate = action.payload;
    },
    setActiveScheduleChange: (state, action) => {
      state.activeScheduleChange = action.payload;
    },
    setChangeSchedules: (state, action) => {
      state.changeSchedules = action.payload;
    },

    // AccountingWarehouseDefects

    // validations
    setStaffValidationState: (state, action) => {
      state.staffValidationState = action.payload;
    },

    setDepartmentId: (state, action) => {
      state.departmentId = action.payload;
    },
    setDepartmentOption: (state, action) => {
      state.departmentOption = action.payload;
    },
    setAllowedDepartments: (state, action) => {
      state.allowedDepartments = action.payload;
    },
    setStaffChashId: (state, action) => {
      state.staffChashId = action.payload;
    },
    setWarehouseSearch: (state, action) => {
      state.WarehouseSearch = action.payload;
    },
    setIncomingSearch: (state, action) => {
      state.IncomingSearch = action.payload;
    },

    // permissions
    setPermissionToWrite: (state, action) => {
      state.permissionToWrite = action.payload;
    },

    setFormState: (state, action) => {
      state.formState = action.payload;
    },
    setRefresh: (state, action) => {
      state.refresh = action.payload;
    },
  },
  extraReducers: globalStateExtraReducers,
});

export const {
  setLeftMenu,
  setLeftMenuDrawer,
  setSelectedStaffs,
  setLeftMenyStates,
  setDropDown,

  setModalDoc15,
  setPrecautionaryModal,
  setMessageModal,
  setAdditionalPaymentModal,
  setWarrningModal,
  setManagmentModal,
  setAddStaffVacationModal,
  setAddStaffChildModal,
  setAclModal,
  setDocsModal,
  setPutDocModal,
  setRankbonusModal,
  setJobtitleAmountModal,
  setStaffJobpositionModal,
  setOtpModal,
  setCashSalaryEarlyReceiptModal,
  setReinstatementStaffManagmentModal,
  setDefectsModal,
  setShowPensionData,
  setUpsertCommonModal,

  setLengthOfService,
  setStaff,
  setStaffs,
  clearStaff,
  setJobposition,
  setJobpositions,

  setScheduleDate,
  setActiveScheduleChange,
  setChangeSchedules,

  setStaffValidationState,
  setDepartmentId,
  setDepartmentOption,
  setAllowedDepartments,

  setWarehouseSearch,
  setIncomingSearch,

  setPermissionToWrite,

  setFormState,
  setRefresh,
} = globalState.actions;
export default globalState.reducer;
