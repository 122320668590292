import {
  documentVerify,
  getDocById,
  getFileById,
  putFileById,
  documentSign,
} from "../../globalStateQueries/StorageQueries";

export const StorageExtraReducers = (builder) => {
  // get doc by id
  builder
    .addCase(documentSign.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(documentSign.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(documentSign.rejected, (state, action) => {
      state.loading = false;
    })

    // get doc by id
    .addCase(getDocById.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getDocById.fulfilled, (state, action) => {
      state.loading = false;
      state.docData = action.payload;
    })
    .addCase(getDocById.rejected, (state, action) => {
      state.loading = false;
    })

    // get file by id
    .addCase(getFileById.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getFileById.fulfilled, (state, action) => {
      state.loading = false;
      state.documentPDf = action.payload;
    })
    .addCase(getFileById.rejected, (state, action) => {
      state.loading = false;
    })

    // get documentVerify by id
    .addCase(documentVerify.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(documentVerify.fulfilled, (state, action) => {
      state.loading = false;
      state.documentVerifyName = action.payload;
    })
    .addCase(documentVerify.rejected, (state, action) => {
      state.loading = false;
    })

    // get file by id
    .addCase(putFileById.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(putFileById.fulfilled, (state, action) => {
      state.loading = false;
      state.putDocModal = { open: false, details: null };
    })
    .addCase(putFileById.rejected, (state, action) => {
      state.loading = false;
    });
};
