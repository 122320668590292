import {
  getPensionAccountingById,
  getPensionDiedOrDisableStaffs,
  getPensioners,
  getPensionOtherStaffData,
  upsertPensionAccounting,
} from "../../globalStateQueries/PesionQueries";

export const PensionExtraReducers = (builder) => {
  // upsert pension acounting
  builder
    .addCase(upsertPensionAccounting.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(upsertPensionAccounting.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(upsertPensionAccounting.rejected, (state, action) => {
      state.loading = false;
    })

    // get pension accounting by id
    .addCase(getPensionAccountingById.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getPensionAccountingById.fulfilled, (state, action) => {
      state.loading = false;
      state.pensionAccountings = action.payload;
    })
    .addCase(getPensionAccountingById.rejected, (state, action) => {
      state.loading = false;
    })

    // get pension accounting by id
    .addCase(getPensioners.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getPensioners.fulfilled, (state, action) => {
      state.loading = false;
      state.pensioners = action.payload;
    })
    .addCase(getPensioners.rejected, (state, action) => {
      state.loading = false;
    })

    //getPensionDiedOrDisableStaffs

    .addCase(getPensionDiedOrDisableStaffs.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getPensionDiedOrDisableStaffs.fulfilled, (state, action) => {
      state.loading = false;
      state.pensionDiedOrDisableStaffs = action.payload;
    })
    .addCase(getPensionDiedOrDisableStaffs.rejected, (state, action) => {
      state.loading = false;
    })

    //getPensionOtherStaffData

    .addCase(getPensionOtherStaffData.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getPensionOtherStaffData.fulfilled, (state, action) => {
      state.loading = false;
      state.pensionOtherStaffData = action.payload;
    })
    .addCase(getPensionOtherStaffData.rejected, (state, action) => {
      state.loading = false;
    });
};
