import { Suspense, lazy, useEffect, useRef, useState } from "react";
import Loader from "../components/loader/Loader";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAcl } from "../redux/reducers/MvdFeuLocalStorageState/AuthQueries";
import { getToken } from "../utils/Token";
import ScannerQRdocument from "../pages/scannerQRdocument/ScannerQRdocument";
import { Toaster } from "react-hot-toast";
import "../styles/textStyle.css";

const Layout = lazy(() => import("../components/layout/Layout"));
const DefaultPage = lazy(() => import("../pages/other/DefaultPage"));

// Login
const Login = lazy(() => import("../pages/login/Login"));

// Admin
const UserHiring = lazy(() => import("../pages/admin/UserHiring"));
const ReferenceBook = lazy(() => import("../pages/admin/ReferenceBook"));

// Payroll Accounting
const Salary = lazy(() => import("../pages/payroll_accounting/Salary"));
const Document3 = lazy(() => import("../pages/payroll_accounting/Document3"));
const Document4 = lazy(() => import("../pages/payroll_accounting/Document4"));
const Document5 = lazy(() => import("../pages/payroll_accounting/Document5"));
const AdditionalPayment = lazy(() =>
  import("../pages/payroll_accounting/AdditionalPayment")
);
const Retention = lazy(() => import("../pages/payroll_accounting/Retention"));

// Cash Accounting
const Document34 = lazy(() => import("../pages/cash_accounting/Document34"));

// Kadr
const Hiring = lazy(() => import("../pages/kadr/Hiring"));
const VacationSchedule = lazy(() => import("../pages/kadr/VacationSchedule"));
const Terminate = lazy(() => import("../pages/kadr/Terminate"));
const Managment = lazy(() => import("../pages/kadr/Managment"));
const Investigation = lazy(() => import("../pages/kadr/Investigation"));
const DisciplinaryAction = lazy(() =>
  import("../pages/kadr/DisciplinaryAction")
);
const BusinessTrip = lazy(() => import("../pages/kadr/BusinessTrip"));
const Document15 = lazy(() => import("../pages/kadr/Document15"));
const TransferOfStaff = lazy(() => import("../pages/kadr/TransferOfStaff"));

// Pension Accounting
const DeceasedStaff = lazy(() =>
  import("../pages/pension_accounting/DeceasedStaff")
);
const DisabledStaff = lazy(() =>
  import("../pages/pension_accounting/DisabledStaff")
);
const PensionAccounting = lazy(() =>
  import("../pages/pension_accounting/PensionAccounting")
);

// Accounting
const ReciptSalary = lazy(() => import("../pages/accounting/ReciptSalary"));
const MoneyCertificate = lazy(() =>
  import("../pages/accounting/MoneyCertificate")
);
const BusinessTripCahs = lazy(() =>
  import("../pages/accounting/BusinessTripCahs")
);
const Agreements = lazy(() => import("../pages/accounting/Agreements"));

// Other
const Page404 = lazy(() => import("../pages/other/Page404"));

// Settings
const ChangePassword = lazy(() => import("../pages/Settings/ChangePassword"));
const UserData = lazy(() => import("../pages/Settings/UserData"));
const Notifications = lazy(() => import("../pages/Settings/Notifications"));

// WarehousePages
const WarehousePages = lazy(() => import("../pages/warehouse/WarehousePages"));

const suspense = (htmlElem) => (
  <Suspense fallback={<Loader />}>{htmlElem}</Suspense>
);

const RoutesCom = () => {
  const userAcl = useSelector(
    ({ MvdFeuLocalStorageState }) => MvdFeuLocalStorageState.userAcl
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const WSRef = useRef();
  const [allowedRoutes, setAllowedRoutes] = useState([]);

  const allRoutes = [
    // { link: "/admin/acl", element: <ACL /> },
    { link: "/admin/common", element: suspense(<ReferenceBook />) },
    { link: "/admin/user", element: suspense(<UserHiring />) },
    { link: "/payment/salary", element: suspense(<Salary />) },
    { link: "/payment/document3", element: suspense(<Document3 />) },
    { link: "/payment/document4", element: suspense(<Document4 />) },
    { link: "/payment/document5", element: suspense(<Document5 />) },
    {
      link: "/payment/additional_payment",
      element: suspense(<AdditionalPayment />),
    },
    { link: "/payment/retention", element: suspense(<Retention />) },
    { link: "/cash_accounting/document34", element: suspense(<Document34 />) },
    { link: "/hr/staff", element: suspense(<Hiring />) },
    { link: "/hr/vacation", element: suspense(<VacationSchedule />) },
    { link: "/hr/terminate", element: suspense(<Terminate />) },
    { link: "/hr/managment", element: suspense(<Managment />) },
    { link: "/hr/investigation", element: suspense(<Investigation />) },
    {
      link: "/hr/disciplinary_action",
      element: suspense(<DisciplinaryAction />),
    },
    { link: "/hr/business_trip", element: suspense(<BusinessTrip />) },
    { link: "/hr/schedule", element: suspense(<Document15 />) },
    { link: "/hr/transfer_of_staff", element: suspense(<TransferOfStaff />) },
    { link: "/pension/deceased_staff", element: suspense(<DeceasedStaff />) },
    { link: "/pension/disabled_staff", element: suspense(<DisabledStaff />) },
    { link: "/pension/pension", element: suspense(<PensionAccounting />) },
    {
      link: "/accounting/cash",
      element: suspense(<ReciptSalary WSRef={WSRef} />),
    },
    { link: "/accounting/counterparty", element: suspense(<Agreements />) },
    {
      link: "/accounting/businessTrip",
      element: suspense(<BusinessTripCahs />),
    },
    { link: "/accounting/warehouse", element: suspense(<WarehousePages />) },
    // { link: "/accounting/money_sertificate", element: <MoneyCertificate /> },
    // { link: '/settings/change_password', element: (<ChangePassword />) }
    { link: "/settings/user_data", element: <UserData /> },
  ];

  useEffect(() => {
    if (userAcl && userAcl.length > 0) {
      const result = userAcl.flatMap((path) => {
        if (path.submodules.some((sub) => sub.submodule === "*")) {
          return allRoutes
            .filter((route) => route.link.startsWith(`/${path.module_alias}`))
            .map((route) => route.link);
        } else {
          return path.submodules.map(
            (el) => `/${path.module_alias}/${el.submodule}`
          );
        }
      });
      setAllowedRoutes(result);
    } else {
      if (getToken()) dispatch(getAcl());
    }
    // eslint-disable-next-line
  }, [userAcl]);

  const isAllowedRouteAvailable = (path) =>
    allowedRoutes && allowedRoutes.some((route) => path.startsWith(route));

  return (
    <>
      <Toaster />
      {allowedRoutes ? (
        <Routes>
          <Route
            path="/login"
            element={suspense(
              <div>
                <Outlet />
              </div>
            )}
          >
            <Route index element={suspense(<Login />)} />
            {/* <Route path="/login/eds" element={suspense(<LoginEDS />)} /> */}
          </Route>
          <Route path="/" element={suspense(<Layout WSRef={WSRef} />)}>
            {allowedRoutes &&
              allRoutes.map((el) =>
                isAllowedRouteAvailable(el.link) ? (
                  <Route
                    path={el.link}
                    key={el.link}
                    element={suspense(el.element)}
                  />
                ) : null
              )}

            <Route index element={suspense(<DefaultPage />)} />
            <Route
              path="/settings/change_password"
              element={suspense(<ChangePassword />)}
            />
            <Route
              path="files/:id/verify"
              element={suspense(<ScannerQRdocument />)}
            />
            <Route
              path="/accounting/agreements"
              element={suspense(<Agreements />)}
            />
            <Route
              path="/settings/user_data"
              element={suspense(<UserData />)}
            />
            <Route
              path="/settings/notifications"
              element={suspense(<Notifications />)}
            />
            <Route
              path="/accounting/money_sertificate"
              element={suspense(<MoneyCertificate />)}
            />
            <Route path="/hr/transfer_of_staff" element={<TransferOfStaff />} />
          </Route>
          <Route
            path="*"
            element={suspense(
              <Page404 error403={!isAllowedRouteAvailable(location.pathname)} />
            )}
          />
        </Routes>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default RoutesCom;
