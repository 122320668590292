//////////////////////       Swagger COMMON          //////////////////////

import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosBaseRequest } from "../../../utils/axiosBaseUrl";
import { getSchedule } from "./HrQueries";
import { axiosRequest } from "../../../utils/axiosURL";

// get modules
export const getModules = createAsyncThunk("getModules", async () => {
  try {
    const { data } = await axiosBaseRequest.get("common/v1/modules");
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

// get department option
export const getDepartmentOption = createAsyncThunk(
  "getDepartmentOption",
  async (body = { limit: 200, offset: 0 }) => {
    try {
      const { data } = await axiosBaseRequest.get(
        `common/v1/department${
          body ? `?limit=${body.limit}&offset=${body.offset}` : ""
        }`,
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  },
);

// get marker option
export const getMarkerOption = createAsyncThunk("getMarkerOption", async () => {
  try {
    const { data } = await axiosBaseRequest.get("common/v1/marker");
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

// get rank option
export const getRankOption = createAsyncThunk("getRankOption", async () => {
  try {
    const { data } = await axiosBaseRequest.get("common/v1/rank");
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

// get gender option
export const getGenderOption = createAsyncThunk("getGenderOption", async () => {
  try {
    const { data } = await axiosBaseRequest.get(`common/v1/gender`);
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

// get month date
export const getMonthDate = createAsyncThunk(
  "getMonthDate",
  async ({ users_id, month, year, shcedule = false }, { dispatch }) => {
    try {
      const { data } = await axiosBaseRequest.get(
        `common/v1/month_days${
          month && year ? `?month=${month}&year=${year}` : ""
        }`,
      );
      if (shcedule) {
        if (data.current_date) {
          const date = data.current_date.split("-");
          dispatch(
            getSchedule({
              year: +date[0],
              month: +date[1],
              staffs: users_id,
            }),
          );
        } else if (month && year) {
          dispatch(
            getSchedule({
              year: +year,
              month: +month,
              staffs: users_id,
            }),
          );
        }
      }
      return data;
    } catch (error) {
      console.log("error", error);
    }
  },
);

// get retentions option
export const getRetentionsOption = createAsyncThunk(
  "getRetentionsOption",
  async () => {
    try {
      const { data } = await axiosBaseRequest.get("common/v1/retentions");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  },
);

// get additional payment
export const getAdditionalPaymentsOption = createAsyncThunk(
  "getAdditionalPaymentsOption",
  async () => {
    try {
      const { data } = await axiosBaseRequest.get(
        "common/v1/additional_payments",
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  },
);

// get staff status
export const getStaffStatus = createAsyncThunk("getStaffStatus", async () => {
  try {
    const { data } = await axiosBaseRequest.get("common/v1/staff_status");
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

// get dismissal types
export const getDismissalTypes = createAsyncThunk(
  "getDismissalTypes",
  async () => {
    try {
      const { data } = await axiosBaseRequest.get("common/v1/dismissal_type");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  },
);

// get disciplinary action
export const getDisciplinaryAction = createAsyncThunk(
  "getDisciplinaryAction",
  async () => {
    try {
      const { data } = await axiosBaseRequest.get(
        `common/v1/disciplinary_action`,
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  },
);

// get calendar year
export const getCalendarYear = createAsyncThunk(
  "getCalendarYear",
  async ({ year }, { dispatch }) => {
    try {
      const { data } = await axiosBaseRequest.get(
        `common/v1/year_calendar?year=${year}`,
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  },
);

// get pension types
export const getPensionTypes = createAsyncThunk("getPensionTypes", async () => {
  try {
    const { data } = await axiosBaseRequest.get(`common/v1/pension_type`);
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

// get document type
export const getDocumentType = createAsyncThunk(
  "getDocumentType",
  async ({ doc_type }, { dispatch }) => {
    try {
      const { data } = await axiosBaseRequest.get(
        `common/v1/document_type${doc_type ? `?doc_type=${doc_type}` : ""}`,
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  },
);

// get deceased staff groups
export const getDeceasedStaffGroups = createAsyncThunk(
  "getDeceasedStaffGroups",
  async () => {
    try {
      const { data } = await axiosBaseRequest.get(
        `common/v1/get/deceased_staff_groups`,
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  },
);

// get disabled staff groups
export const getDisabledStaffGroups = createAsyncThunk(
  "getDisabledStaffGroups",
  async () => {
    try {
      const { data } = await axiosBaseRequest.get(
        `common/v1/get/disabled_staff_groups`,
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  },
);

// get staff financial aid
export const getStaffFinancialAid = createAsyncThunk(
  "getStaffFinancialAid",
  async () => {
    try {
      const { data } = await axiosBaseRequest.get(
        `common/v1/get/staff_financial_aid`,
      );
      return [{ ...data[0], amount: data[0].amount / 100 }];
    } catch (error) {
      console.log("error", error);
    }
  },
);

// get vacation types
export const getVacationOption = createAsyncThunk(
  "getVacationOption",
  async () => {
    try {
      const { data } = await axiosBaseRequest.get(
        "common/v1/get/vacation_group",
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  },
);

// get trip daily pay
export const getTripDailyPayOptions = createAsyncThunk(
  "getTripDailyPayOptions",
  async () => {
    try {
      const { data } = await axiosBaseRequest.get(
        "common/v1/get/trip_daily_pay",
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  },
);

// get jobtitle option
export const getJobtitleOption = createAsyncThunk(
  "getJobtitleOption",
  async () => {
    try {
      const { data } = await axiosBaseRequest.get("common/v1/jobtitle");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  },
);

// get countries
export const getCountries = createAsyncThunk("getCountries", async () => {
  try {
    const { data } = await axiosBaseRequest.get("common/v1/get/countries");
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

// get region
export const getRegion = createAsyncThunk("getRegion", async () => {
  try {
    const { data } = await axiosBaseRequest.get("common/v1/get/region");
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

// get pension awards
export const getPensionAwards = createAsyncThunk(
  "getPensionAwards",
  async () => {
    try {
      const { data } = await axiosBaseRequest.get(
        "common/v1/get/pension_awards",
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  },
);

// get category
export const getCategory = createAsyncThunk("getCategory", async () => {
  try {
    const { data } = await axiosRequest.get(`common/v1/product_category/`);
    return data;
  } catch (error) {
    console.log("error", error);
  }
});
// get document
export const getNamenclatura = createAsyncThunk(
  "getNamenclatura",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.get(`common/v1/nomenclature`);

      return data;
    } catch (error) {
      console.error("Ошибка при получении номенклатуры:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);


export const getUnits = createAsyncThunk(
  "getUnits",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.get(`common/v1/units`);
      return data;
    } catch (error) {
      console.error("Ошибка при получении единиц измерения:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);
