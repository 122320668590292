import {
  getAclAdmin,
  getAclAdminById,
  getJobtitleAmount,
  getRankbonus,
  getRoleOption,
  getUsers,
  upsertAcl,
  upsertAdditionalPayment,
  upsertCountries,
  upsertDeceasedStaffGroups,
  upsertDepartment,
  upsertDisabledStaffGroups,
  upsertDisciplinaryAction,
  upsertDismissalType,
  upsertDocumentType,
  upsertGender,
  upsertJobtitle,
  upsertJobtitleAmout,
  upsertMarker,
  upsertPensionAwards,
  upsertPensionType,
  upsertRank,
  upsertRankbonus,
  upsertRegion,
  upsertRetention,
  upsertRole,
  upsertStaffFinancialAid,
  upsertStaffStatus,
  upsertTripDailyPayOption,
  upsertUser,
  upsertVacationOption,
} from "../../globalStateQueries/AdminQueries";

export const AdminExtraReducers = (builder) => {
  // upsert user ==================
  builder
    .addCase(upsertUser.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(upsertUser.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(upsertUser.rejected, (state, action) => {
      state.loading = false;
    })

    // get role option
    .addCase(getRoleOption.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getRoleOption.fulfilled, (state, action) => {
      state.loading = false;
      state.roleOption = action.payload;
    })
    .addCase(getRoleOption.rejected, (state, action) => {
      state.loading = false;
    })

    // get users option
    .addCase(getUsers.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.users = action.payload;
    })
    .addCase(getUsers.rejected, (state, action) => {
      state.loading = false;
    })

    // get acl admin
    .addCase(getAclAdmin.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getAclAdmin.fulfilled, (state, action) => {
      state.loading = false;
      state.aclAdmin = action.payload;
    })
    .addCase(getAclAdmin.rejected, (state, action) => {
      state.loading = false;
    })

    // upsert acl
    .addCase(upsertAcl.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(upsertAcl.fulfilled, (state, action) => {
      state.loading = false;
      state.aclAdmin = action.payload;
    })
    .addCase(upsertAcl.rejected, (state, action) => {
      state.loading = false;
    })

    // get acl admin by id
    .addCase(getAclAdminById.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getAclAdminById.fulfilled, (state, action) => {
      state.loading = false;
      state.aclAdminByRoleId = action.payload;
    })
    .addCase(getAclAdminById.rejected, (state, action) => {
      state.loading = false;
    })

    // upsert role option
    .addCase(upsertRole.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(upsertRole.fulfilled, (state, action) => {
      state.loading = false;
      // if (action.payload?.id) {
      //   state.roleOption = state.roleOption?.map((el) => {
      //     if (el.id === action.payload?.id) {
      //       return action.payload;
      //     }
      //     return el;
      //   });
      // } else if (action.payload?.name) {
      //   state.roleOption = [...state.roleOption, action.payload];
      // }
    })
    .addCase(upsertRole.rejected, (state, action) => {
      state.loading = false;
    })

    // upsert department option
    .addCase(upsertDepartment.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(upsertDepartment.fulfilled, (state, action) => {
      state.loading = false;
      // if (action.payload?.id) {
      //   state.departmentOption = state.departmentOption?.map((el) => {
      //     if (el.id === action.payload?.id) {
      //       return action.payload;
      //     }
      //     return el;
      //   });
      // } else if (action.payload?.name) {
      //   state.departmentOption = [...state.departmentOption, action.payload];
      // }
    })
    .addCase(upsertDepartment.rejected, (state, action) => {
      state.loading = false;
    })

    // upsert rank
    .addCase(upsertRank.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(upsertRank.fulfilled, (state, action) => {
      state.loading = false;
      // if (action.payload?.id) {
      //   state.rankOption = state.rankOption?.map((el) => {
      //     if (el.id === action.payload?.id) {
      //       return action.payload;
      //     }
      //     return el;
      //   });
      // } else if (action.payload?.name) {
      //   state.rankOption = [...state.rankOption, action.payload];
      // }
    })
    .addCase(upsertRank.rejected, (state, action) => {
      state.loading = false;
    })

    // upsert gender
    .addCase(upsertGender.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(upsertGender.fulfilled, (state, action) => {
      state.loading = false;
      // if (action.payload?.id) {
      //   state.genderOption = state.genderOption?.map((el) => {
      //     if (el.id === action.payload?.id) {
      //       return action.payload;
      //     }
      //     return el;
      //   });
      // } else if (action.payload?.name) {
      //   state.genderOption = [...state.genderOption, action.payload];
      // }
    })
    .addCase(upsertGender.rejected, (state, action) => {
      state.loading = false;
    })

    // upsert marker
    .addCase(upsertMarker.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(upsertMarker.fulfilled, (state, action) => {
      state.loading = false;
      // if (action.payload?.id) {
      //   state.markerOption = state.markerOption?.map((el) => {
      //     if (el.id === action.payload?.id) {
      //       return action.payload;
      //     }
      //     return el;
      //   });
      // } else if (action.payload?.name) {
      //   state.markerOption = [...state.markerOption, action.payload];
      // }
    })
    .addCase(upsertMarker.rejected, (state, action) => {
      state.loading = false;
    })

    // upsert retention
    .addCase(upsertRetention.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(upsertRetention.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(upsertRetention.rejected, (state, action) => {
      state.loading = false;
    })

    // upsert additional payment
    .addCase(upsertAdditionalPayment.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(upsertAdditionalPayment.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(upsertAdditionalPayment.rejected, (state, action) => {
      state.loading = false;
    })

    // upsert staff status option
    .addCase(upsertStaffStatus.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(upsertStaffStatus.fulfilled, (state, action) => {
      state.loading = false;
      // if (action.payload?.id) {
      //   state.staffStatus = state.staffStatus?.map((el) => {
      //     if (el.id === action.payload?.id) {
      //       return action.payload;
      //     }
      //     return el;
      //   });
      // } else if (action.payload?.name) {
      //   state.staffStatus = [...state.staffStatus, action.payload];
      // }
    })
    .addCase(upsertStaffStatus.rejected, (state, action) => {
      state.loading = false;
    })

    // upsert desmissial type
    .addCase(upsertDismissalType.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(upsertDismissalType.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(upsertDismissalType.rejected, (state, action) => {
      state.loading = false;
    })

    // upsert disciplinary action
    .addCase(upsertDisciplinaryAction.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(upsertDisciplinaryAction.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(upsertDisciplinaryAction.rejected, (state, action) => {
      state.loading = false;
    })

    // upsert pension type
    .addCase(upsertPensionType.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(upsertPensionType.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(upsertPensionType.rejected, (state, action) => {
      state.loading = false;
    })

    // upsert document type
    .addCase(upsertDocumentType.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(upsertDocumentType.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(upsertDocumentType.rejected, (state, action) => {
      state.loading = false;
    })

    // upsert deceased staff groups
    .addCase(upsertDeceasedStaffGroups.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(upsertDeceasedStaffGroups.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(upsertDeceasedStaffGroups.rejected, (state, action) => {
      state.loading = false;
    })

    // upsert disabled staff groups
    .addCase(upsertDisabledStaffGroups.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(upsertDisabledStaffGroups.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(upsertDisabledStaffGroups.rejected, (state, action) => {
      state.loading = false;
    })

    // upsert staff financial aid
    .addCase(upsertStaffFinancialAid.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(upsertStaffFinancialAid.fulfilled, (state, action) => {
      state.loading = false;
      // if (action.payload?.id) {
      //   state.staffFinancialAid = state.staffFinancialAid?.map((el) => {
      //     if (el.id === action.payload?.id) {
      //       return action.payload;
      //     }
      //     return el;
      //   });
      // } else if (action.payload?.name) {
      //   state.staffFinancialAid = [...state.staffFinancialAid, action.payload];
      // }
    })
    .addCase(upsertStaffFinancialAid.rejected, (state, action) => {
      state.loading = false;
    })

    // upsert vacation types
    .addCase(upsertVacationOption.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(upsertVacationOption.fulfilled, (state, action) => {
      state.loading = false;
      // if (action.payload?.id) {
      //   state.vacationOption = state.vacationOption?.map((el) => {
      //     if (el.id === action.payload?.id) {
      //       return action.payload;
      //     }
      //     return el;
      //   });
      // } else if (action.payload?.name) {
      //   state.vacationOption = [...state.vacationOption, action.payload];
      // }
    })
    .addCase(upsertVacationOption.rejected, (state, action) => {
      state.loading = false;
    })

    // upsert trip daily
    .addCase(upsertTripDailyPayOption.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(upsertTripDailyPayOption.fulfilled, (state, action) => {
      state.loading = false;
      // if (action.payload?.record_id) {
      //   state.tripDailyPayOptions = state.tripDailyPayOptions?.map((el) => {
      //     if (el.record_id === action.payload?.record_id) {
      //       return action.payload;
      //     }
      //     return el;
      //   });
      // } else if (action.payload?.name) {
      //   state.tripDailyPayOptions = [
      //     ...state.tripDailyPayOptions,
      //     action.payload,
      //   ];
      // }
    })
    .addCase(upsertTripDailyPayOption.rejected, (state, action) => {
      state.loading = false;
    })

    // upsert jobtitle
    .addCase(upsertJobtitle.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(upsertJobtitle.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(upsertJobtitle.rejected, (state, action) => {
      state.loading = false;
    })

    // upsert countries
    .addCase(upsertCountries.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(upsertCountries.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(upsertCountries.rejected, (state, action) => {
      state.loading = false;
    })

    // upsert region
    .addCase(upsertRegion.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(upsertRegion.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(upsertRegion.rejected, (state, action) => {
      state.loading = false;
    })

    // upsert pension awards
    .addCase(upsertPensionAwards.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(upsertPensionAwards.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(upsertPensionAwards.rejected, (state, action) => {
      state.loading = false;
    })

    // upsert rankbonus
    .addCase(upsertRankbonus.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(upsertRankbonus.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(upsertRankbonus.rejected, (state, action) => {
      state.loading = false;
    })

    // get rankbonus
    .addCase(getRankbonus.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getRankbonus.fulfilled, (state, action) => {
      state.loading = false;
      state.rankbonus = action.payload;
    })
    .addCase(getRankbonus.rejected, (state, action) => {
      state.loading = false;
    })

    // upsert jobtitl amount
    .addCase(upsertJobtitleAmout.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(upsertJobtitleAmout.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(upsertJobtitleAmout.rejected, (state, action) => {
      state.loading = false;
    })

    // get jobtitle amount
    .addCase(getJobtitleAmount.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getJobtitleAmount.fulfilled, (state, action) => {
      state.loading = false;
      state.jobtitleAmount = action.payload;
    })
    .addCase(getJobtitleAmount.rejected, (state, action) => {
      state.loading = false;
    });
};
